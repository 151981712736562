.container{
    
}

.audioPreview{
    display: flex;
    align-items: center;
}

.songsRow{
    display: flex;
    align-items: center;
    border: 1px solid rgb(134, 134, 134);
    border-radius: 7px;
    margin: 10px 0;
    padding: 7px 0;
}

.moveIcon{
    cursor: move;
}